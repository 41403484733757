const EllipseSvg = ({ brand_color }) => {
    return (
        <svg
            width="1023"
            height="1117"
            viewBox="0 0 1023 1117"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_f_2525_3419)">
                <path
                    d="M1294.48 618.877C1284.43 738.715 1237.11 852.399 1159.15 943.971C1081.2 1035.54 976.526 1100.4 859.827 1129.45C743.128 1158.49 620.263 1150.26 508.48 1105.91C396.697 1061.56 301.609 983.324 236.562 882.174C171.516 781.025 139.778 662.045 145.804 541.937C151.83 421.829 195.317 306.624 270.161 212.493C345.004 118.362 447.447 50.0315 563.107 17.094C678.767 -15.8434 801.839 -11.7343 915.045 28.8446L868.782 157.908C782.536 126.993 688.774 123.862 600.658 148.955C512.542 174.049 434.496 226.106 377.477 297.82C320.457 369.534 287.327 457.303 282.736 548.807C278.145 640.311 302.324 730.956 351.88 808.017C401.435 885.077 473.879 944.685 559.041 978.472C644.203 1012.26 737.807 1018.53 826.714 996.401C915.621 974.273 995.367 924.86 1054.76 855.096C1114.15 785.333 1150.2 698.723 1157.85 607.424L1294.48 618.877Z"
                    fill={brand_color}
                    fillOpacity="0.3"
                />
            </g>
            <defs>
                <filter
                    id="filter0_f_2525_3419"
                    x="0.0810547"
                    y="-149.92"
                    width="1439.4"
                    height="1441.41"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feGaussianBlur
                        stdDeviation="72.5"
                        result="effect1_foregroundBlur_2525_3419"
                    />
                </filter>
                <linearGradient
                    id="paint0_linear_2525_3419"
                    x1="768.878"
                    y1="-2.90766"
                    x2="672.693"
                    y2="1144.48"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#8DF3FF" />
                    <stop offset="1" stopColor="#27A9E1" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default EllipseSvg;
