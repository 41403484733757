import { getHeader, hex2rgb } from "@/utilities";
import { useRouter } from "next/router";
import SignUpPosterSvg from "@/components/SignUpPosterSvg";
import EllipseSvg from "@/components/EllipseSvg";
import ReactDOMServer from "react-dom/server";
import Style from "react-style-tag/lib/Style";
import Image from "next/image";

const GuestLayout = ({
    children,
    headers,
    organizationBrandColor = "#178F9D",
}) => {
    const router = useRouter();
    const { pathname } = router;
    const svgString = encodeURIComponent(
        ReactDOMServer.renderToStaticMarkup(
            <EllipseSvg brand_color={organizationBrandColor} />
        )
    );

    return (
        <>
            {getHeader(pathname, headers)}
            <div className="relative flex min-h-screen bg-white justify-center lg:px-0">
                <div
                    style={{ backgroundColor: "#00000" }}
                    className="customOrgBackground relative hidden justify-center items-center flex-1 w-1/2 lg:flex bg-[url('../../public/signup-background.gif')] overflow-hidden bg-cover
                    before:content-[''] before:absolute before:w-full before:h-full before:bg-transparent-teal-rgba
                    before:inset-0"
                >
                    <div className="flex flex-col my-auto">
                        <div className="absolute w-full h-[97vh] top-[-1%] left-0">
                            <Image
                                className="mx-auto w-full h-full object-contain"
                                src={"/women-smiling.png"}
                                alt="Tacnique Logo"
                                layout="fill"
                                objectFit="contain"
                                loading="lazy"
                            />
                            <div className="absolute mx-auto flex h-1/4 w-full top-[60%] left-[-10rem]">
                                <SignUpPosterSvg
                                    brand_color={organizationBrandColor}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="ellipseSvg relative z-10 flex flex-1 lg:w-1/2 md:w-full bg-white px-4 sm:justify-center md:flex-none overflow-hidden justify-between flex-col shadow-lg bg-transparent-black-rgba
                    before:bg-[url('../../public/Ellipse.png')] before:absolute before:content-[''] before:w-[120%] before:h-[137%] before:top-[-33%]
                    before:left-[-18%] before:bottom-0 before:right-0 before:bg-cover before:z-[-1]"
                >
                    <div className="mx-auto w-full sm:px-4 md:w-full">
                        {children}
                    </div>

                    <div className="text-center pt-10">
                        <span className="text-sm">© 2024 tacnique</span>
                    </div>
                </div>
            </div>

            {organizationBrandColor && (
                <Style>
                    {`
                    .customOrgBackground&:before {
                        background-color: ${hex2rgb(organizationBrandColor)};
                    }
                    .ellipseSvg&:before {
                        background-image: url("data:image/svg+xml,${svgString}");
                    }
                `}
                </Style>
            )}
        </>
    );
};

export default GuestLayout;
