import { classNames } from "@/utilities";

const AuthCard = ({ children, referralCode, organizationBrandColor }) => (
    <div className="min-h-[90vh] flex flex-col justify-center items-center sm:pt-6">
        <div
            className={classNames(
                "w-full sm:max-w-md lg:max-w-lg px-2 lg:px-6 md:px-4 py-4 shadow-md overflow-hidden sm:rounded-lg",
                referralCode && "border border-b-8 border-t-0 border-x-0"
            )}
            style={{ borderBottomColor: organizationBrandColor }}
        >
            {children}
        </div>
    </div>
);

export default AuthCard;
